import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DefaultPermissions } from 'dashboard/modules/user/permissions.interface';
import { Observable } from 'rxjs';
import { WizardSteps } from 'shared/modules/modal/wizard-modal/wizard-modal.component';
import { ColumnConfig } from 'shared/modules/table/table-config.model';

export type FormConfigType = 'wizard' | 'common';
export type FormConfig<T extends FormConfigType> = T extends 'wizard' ? WizardSteps[] :
    T extends 'common' ? FormlyFieldConfig[] :
        never;
export type FromConfigObservable<T extends FormConfigType> = Observable<FormConfig<T>>;

export function formTypeIs<T extends FormConfigType, Y extends FormConfigType>
(constant: T, variable: Y, form: FormConfig<any>): form is FormConfig<T> {
    return constant === variable as any;
}

export class CRUDForms<T extends FormConfigType, C extends FormConfigType, U extends FormConfigType> {
    formConfigType?: T;
    formConfig?: FromConfigObservable<T>;
    toFormSerializer?: Function;
    fromFormSerializer?: Function;

    createFormConfigType?: C;
    createFormConfig?: FromConfigObservable<C>;
    createFromFormSerializer?: Function;

    updateFormConfigType?: U;
    updateFormConfig?: FromConfigObservable<U>;
    updateFromFormSerializer?: Function;

}

export interface IDefaultCrudSettingsConstructor {
    title?: string;
    baseUrl: string;
    getOnUpdate?: boolean;
    stickyUpdateBtn?: boolean;
    permissions: DefaultPermissions;
    columns: ColumnConfig[];
    links?: {label, routerLink, permission?}[];
}

// tslint:disable:max-line-length
@Injectable()
export class DefaultCrudSettingsConstructor<T extends FormConfigType = 'common', C extends FormConfigType = 'common', U extends FormConfigType = 'common'>
    extends CRUDForms<T, C, U>
    implements IDefaultCrudSettingsConstructor {
    title?: string;
    baseUrl: string;
    getOnUpdate?: boolean;
    stickyUpdateBtn?: boolean;
    permissions: DefaultPermissions;
    columns: ColumnConfig[];
    links?: {label, routerLink, permission?}[] = null;

    constructor(payload: IDefaultCrudSettingsConstructor & CRUDForms<T, C, U>) {
        super();
        Object.assign(this, payload);
    }
}
