import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, finalize, map } from 'rxjs/operators';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
    errorsToSkip = 0;
    errorsSink$ = new Subject<HttpErrorResponse>();

    constructor(private toastr: ToastrService, private translate: TranslateService) {
        this.errorsSink$
            .pipe(debounceTime(0))
            .subscribe(err => {
                let msg;
                switch (err.status) {
                    case 0:
                        msg = this.translate.instant('ERROR_CONNECTION');
                        break;
                    case 403:
                        msg = this.translate.instant('ERROR_ACCESS');
                        break;
                    case 422:
                        msg = err.error.hasOwnProperty('error_message') ? err.error.error_message : JSON.parse(err.error).error_message;
                        break;
                    default:
                        msg = this.translate.instant('ERROR_ADMINISTRATOR');
                }
                this.toastr.error(msg);
            });
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const skipError = !!this.errorsToSkip;
        return next.handle(request).pipe(
            map((x) => {
                    if (x.type === HttpEventType.Response && x.ok === false && !skipError) {
                        this.toastr.error(x.body.error_message);
                    }
                    return x;
                },
            ),
            // tap(() => null, (err) => {
            //     if (err instanceof HttpErrorResponse && !skipError) { this.errorsSink$.next(err); }
            // }),
            catchError((err: HttpErrorResponse) => {
                const customErrorHandling = request.headers.get('Grid-Custom-Error-Handling');
                const errorMessage = err?.error?.data?.report_template_id;
                let msg;

                if (skipError && err.status !== 403) {
                    throw err;
                }

                if (err.error instanceof ArrayBuffer) {
                    const decoder = new TextDecoder('utf-8');
                    const parsedError = JSON.parse(decoder.decode(err.error));
                    msg = parsedError['error_message'];
                } else {
                    msg = err.error['error_message'];
                }

                switch (err.status) {
                    case 0:
                        msg = this.translate.instant('ERROR_CONNECTION');
                        break;
                    case 403:
                        msg = this.translate.instant('ERROR_ACCESS');
                        break;
                    case 422:
                        // noinspection SillyAssignmentJS
                        !customErrorHandling ? msg = msg : msg = errorMessage;
                        break;
                    default:
                        msg = this.translate.instant('ERROR_ADMINISTRATOR');
                }
                this.toastr.error(msg);
                return throwError(err);
            }),
            finalize(() => skipError ? this.errorsToSkip-- : null),
        );
    }
}
