<app-modal [title]="title" [modalRef]="modalRef" [size]="modalSize">
    <ng-content select=".custom-html"></ng-content>
    <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="submit.next(model)">
            <div class="custom-html" *ngIf="customHtml" [innerHTML]="customHtml"></div>
            <formly-form [class]="formlyClass"
                         [model]="model"
                         [fields]="fields"
                         [form]="form">
            </formly-form>

            <ng-container *ngIf="template">
                <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ $implicit: model }"></ng-template>
            </ng-container>

            <ng-container *ngIf="(err$ | async) as err">
                <hr>
                <div [innerHtml]="err" class="alert-danger p-3"></div>
            </ng-container>
            <ng-container *ngIf="buttonTitle && buttonTitle !== '@HIDE@'">
                <hr>
                <button type="submit" class="btn btn-lg btn-primary w-100"
                        [ngClass]="{'stickyModalBtn' : buttonSticky}"
                        [disabled]="!form.valid">{{ buttonTitle }}</button>
            </ng-container>
        </form>
    </div>
</app-modal>
